html, 
body {
    height: 100%;
    overflow: auto;
}

body {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(238,130,238, 0.64)), to(#7B68EE));
}

#root {
    height: 100%;
}
