.Nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

.NavLabelContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.NavLabel {
    margin: 10px;
    padding: 10px;
    border-radius: 16px;
    border-color: aqua;
    border-style: solid;
    font-size: x-large;
    display: flex;
}

.NavLabel:active {
    border-color:black;
}

.ActiveNavLabel {
    border-color:blueviolet;
}

.Name {
    padding: 25px;
    margin-left: 100px;
    margin-right:auto;
    justify-self: flex-start;
}

a:link {
    text-decoration: none;
    color: black;
}

a:visited {
    text-decoration: none;
    color:black;
}
